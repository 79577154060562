<template>
  <div class="mt-5 mb-5">
    <v-container>
      <v-card class="mb-5 shop-request-form-header">
        <v-card-title class="text-center">
          Request for Shop
        </v-card-title>
      </v-card>
      <v-form v-model="isValidForm">
        <v-row>
          <!--left side-->
          <v-col
            cols="12"
            lg="7"
          >
            <v-card>
              <v-card-text>
                <v-container>
                  <v-row>
                    <v-col cols="12">
                      <v-text-field
                        v-model.trim="shopCreateForm.name"
                        :error-messages="errors['name']"
                        :rules="validator.name"
                        class="ma-0 pa-0"
                        label="Shop Name *"
                        outlined
                        color="secondary"
                      />
                    </v-col>
                    <v-col cols="12">
                      <v-text-field
                        v-model.trim="shopCreateForm.slug"
                        :error-messages="errors['slug']"
                        :rules="validator.slug"
                        class="ma-0 pa-0"
                        label="URI Slug *"
                        outlined
                        color="secondary"
                      />
                    </v-col>
                    <v-col cols="12">
                      <v-text-field
                        v-model.trim="shopCreateForm.cell"
                        :error-messages="errors['cell']"
                        :rules="validator['phone']"
                        class="ma-0 pa-0"
                        label="Phone *"
                        outlined
                        color="secondary"
                      />
                    </v-col>
                    <v-col cols="12">
                      <v-text-field
                        v-model.trim="shopCreateForm.email"
                        :error-messages="errors['email']"
                        :rules="validator.email"
                        class="ma-0 pa-0"
                        label="Email *"
                        outlined
                        color="secondary"
                      />
                    </v-col>
                    <v-col cols="12">
                      <v-textarea
                        v-model.trim="shopCreateForm.address"
                        :error-messages="errors['address']"
                        class="ma-0 pa-0"
                        label="Shop Address"
                        outlined
                        color="secondary"
                      />
                    </v-col>
                    <v-col cols="12">
                      <v-textarea
                        v-model.trim="shopCreateForm.about"
                        :error-messages="errors['about']"
                        class="ma-0 pa-0"
                        label="About the Shop"
                        outlined
                        color="secondary"
                      />
                    </v-col>
                  </v-row>
                </v-container>
              </v-card-text>
            </v-card>
          </v-col>

          <!--right side-->
          <v-col
            cols="12"
            lg="5"
          >
            <!--images-->
            <v-card>
              <v-card-text>
                <v-row>
                  <v-col
                    v-if="logoPreview"
                    cols="12"
                  >
                    <v-responsive class="d-flex justify-center align-center text-center">
                      <img
                        :src="logoPreview"
                        alt="logo Preview"
                        style="max-height: 300px"
                      >
                    </v-responsive>
                  </v-col>
                  <v-col cols="12">
                    <ImageCropper
                      :height="350"
                      :width="350"
                      @crop="handleLogoSelect"
                    >
                      <template #default="{ loadImage }">
                        <v-file-input
                          ref="logo"
                          color="secondary"
                          label="Shop Logo"
                          prepend-inner-icon="mdi-camera"
                          accept="image/*"
                          prepend-icon=""
                          outlined
                          :error-messages="errors['logo']"
                          @change="loadImage"
                          @click:prepend-inner="$refs.logo.$refs.input.click()"
                          @click:clear="clearFileSelect('logo')"
                        />
                      </template>
                    </ImageCropper>
                  </v-col>
                  <v-col
                    v-if="bannerPreview"
                    cols="12"
                  >
                    <v-responsive class="d-flex justify-center align-center text-center">
                      <img
                        :src="bannerPreview"
                        alt="Banner preview"
                        style="max-height: 300px"
                      >
                    </v-responsive>
                  </v-col>
                  <v-col cols="12">
                    <ImageCropper
                      :height="200"
                      :width="1080"
                      @crop="handleBannerSelect"
                    >
                      <template #default="{ loadImage }">
                        <v-file-input
                          ref="banner"
                          color="secondary"
                          label="Shop Banner"
                          prepend-inner-icon="mdi-camera"
                          accept="image/*"
                          prepend-icon=""
                          outlined
                          :error-messages="errors['banner']"
                          @change="loadImage"
                          @click:prepend-inner="$refs.banner.$refs.input.click()"
                          @click:clear="clearFileSelect('banner')"
                        />
                      </template>
                    </ImageCropper>
                  </v-col>
                  <!--                  <v-col cols="12">-->
                  <!--                    <v-file-input-->
                  <!--                      ref="bannerInput"-->
                  <!--                      :error-messages="errors['banner']"-->
                  <!--                      accept="image/*"-->
                  <!--                      label="Upload Banner"-->
                  <!--                      outlined-->
                  <!--                      prepend-icon=""-->
                  <!--                      prepend-inner-icon="mdi-camera"-->
                  <!--                      show-size-->
                  <!--                      color="secondary"-->
                  <!--                      @change="handleBannerInput($event)"-->
                  <!--                      @click:prepend-inner="$refs.imageInput.$refs.bannerInput.click()"-->
                  <!--                      @click:clear="bannerPreview = null"-->
                  <!--                    />-->

                  <!--                  </v-col>-->
                </v-row>
              </v-card-text>
            </v-card>

            <!--social media links-->
            <v-card class="my-5">
              <v-card-text>
                <v-row class="social-media-link">
                  <v-col
                    cols="12"
                    lg="6"
                  >
                    <v-text-field
                      v-model.trim="shopCreateForm.social_media.facebook"
                      :error-messages="errors['social_media.facebook']"
                      class="ma-0 pa-0"
                      hint="please provide the URL link to your Facebook page"
                      label="Facebook"
                      outlined
                      color="secondary"
                      prepend-inner-icon="mdi-facebook"
                    />
                  </v-col>

                  <v-col
                    cols="12"
                    lg="6"
                  >
                    <v-text-field
                      v-model="shopCreateForm.social_media.messenger"
                      :error-messages="errors['social_media.messenger']"
                      class="ma-0 pa-0"
                      hint="please provide the URL link to your messenger"
                      label="Messenger"
                      outlined
                      color="secondary"
                      prepend-inner-icon="mdi-facebook-messenger"
                    />
                  </v-col>

                  <v-col
                    cols="12"
                    lg="6"
                  >
                    <v-text-field
                      v-model="shopCreateForm.social_media.twitter"
                      :error-messages="errors['social_media.twitter']"
                      class="ma-0 pa-0"
                      hint="pPlease provide the URL link to your Twitter account"
                      label="Twitter"
                      outlined
                      color="secondary"
                      prepend-inner-icon="mdi-twitter"
                    />
                  </v-col>

                  <v-col
                    cols="12"
                    lg="6"
                  >
                    <v-text-field
                      v-model="shopCreateForm.social_media.skype"
                      :error-messages="errors['social_media.skype']"
                      class="ma-0 pa-0"
                      hint="Please provide the URL link to your Twitter account Skype account"
                      label="Skype"
                      outlined
                      color="secondary"
                      prepend-inner-icon="mdi-skype"
                    />
                  </v-col>

                  <v-col
                    cols="12"
                    lg="6"
                  >
                    <v-text-field
                      v-model="shopCreateForm.social_media.youtube"
                      :error-messages="errors['social_media.youtube']"
                      class="ma-0 pa-0"
                      hint="please provide the URL link to your youtube channel"
                      label="Youtube"
                      outlined
                      prepend-inner-icon="mdi-youtube"
                    />
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>

            <v-card class="my-5">
              <v-card-text>
                <v-checkbox
                  v-model="shopCreateForm.termsAndCondition"
                  :rules="validator.termsAndCondition"
                  color="secondary"
                >
                  <template #label>
                    <div class="">
                      I have read the
                      <a
                        href="/terms-and-condition"
                        target="_blank"
                        v-on="on"
                        @click.stop
                      >
                        terms and condition
                      </a>
                      and i am agree *
                    </div>
                  </template>
                </v-checkbox>
                <v-checkbox
                  v-model="shopCreateForm.privacyPolicy"
                  :rules="validator.termsAndCondition"
                  color="secondary"
                >
                  <template #label>
                    <div>
                      I have read the
                      <a
                        href="/privacy-policy"
                        target="_blank"
                        v-on="on"
                        @click.stop
                      >
                        privacy policy
                      </a>
                      and i am agree *
                    </div>
                  </template>
                </v-checkbox>
              </v-card-text>
              <v-card-text>
                <div class="d-flex justify-end">
                  <v-btn
                    :disabled="!isValidForm"
                    :loading="formSubmitting"
                    color="primary"
                    @click="handleShopCreateRequest"
                  >
                    Submit
                  </v-btn>
                </div>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-form>
    </v-container>
  </div>
</template>

<script>
import { shopCreateRequest } from '@/api/shop';
import Toast from '@/utils/Toast';
import routeMap from '@/utils/routeMap';
import ImageCropper from '@/components/Utils/ImageCropper';
import { userShops } from '@/api/user';

export default {
  name: 'CreateShop',
  components: {
    ImageCropper
  },
  data: function () {
    return {
      isValidForm: false,
      errors: {},
      logoPreview: null,
      bannerPreview: null,
      on: '',
      formSubmitting: false,

      shopCreateForm: {
        name: '',
        slug: '',
        about: '',
        cell: '',
        email: '',
        address: '',
        logo: '',
        banner: '',

        privacyPolicy: false,
        termsAndCondition: false,

        social_media: {
          facebook: '',
          twitter: '',
          youtube: '',
          skype: '',
          messenger: ''
        }
      },
      validator: {
        name: [
          (v) => !!v || 'Name is required',
          (v) => !!v && v.trim().length >= 3 || 'Name must be 3 or more Character',
          (v) => v.length <= 35 || 'Name must not more than 35 Character'
        ],

        slug: [
          v => !!v || 'Slug is required',
          v => !!v && v.trim().length >= 5 || 'Slug must be 5 or more Character',
          v => v.length <= 50 || 'Slug 40 must not more than 40 Character'
        ],

        phone: [
          (v) => !!v || 'Phone Number is required',
          (v) => !isNaN(parseInt(v)) || 'Phone number must be number',
          (v) => v.trim().length >= 9 || 'Phone number must be 9 or more Character',
          (v) => v.length <= 13 || 'Phone number must not more than 13 Character'
        ],

        email: [
          (v) => !!v || 'Email is required',
          (v) => /.+@.+\..+/.test(v) || 'E-mail must be valid email'
        ],

        privacyPolicy: [
          (v) => !!v || 'You must agree to continue!'
        ],

        termsAndCondition: [
          (v) => !!v || 'You must agree to continue!'
        ]
      }
    };
  },
  watch: {
    'shopCreateForm.name': {
      handler(data) {
        if (!data) return;

        this.shopCreateForm.slug = this.slugify(data);
        this.errors['name'] = [];
      }
    },

    'shopCreateForm.slug': {
      handler(data) {
        if (!data) return;
        this.shopCreateForm.slug = this.slugify(data);
        this.errors['slug'] = [];
      }
    },

    'shopCreateForm.about': {
      handler(data) {
        if (!data) return;

        this.errors['about'] = [];
      }
    },

    'shopCreateForm.cell': {
      handler(data) {
        if (!data) return;

        this.errors['cell'] = [];
      }
    },

    'shopCreateForm.email': {
      handler(data) {
        if (!data) return;

        this.errors['email'] = [];
      }
    },

    'shopCreateForm.address': {
      handler(data) {
        if (!data) return;

        this.errors['address'] = [];
      }
    },

    'shopCreateForm.logo': {
      handler(data) {
        if (!data) return;

        this.errors['logo'] = [];
      }
    },

    'shopCreateForm.banner': {
      handler(data) {
        if (!data) return;

        this.errors['banner'] = [];
      }
    }
  },
  async beforeMount() {
    try {
      const { data } = await userShops();

      const shops = data.data ?? [];

      const hasActiveShop = shops.find((shop) => shop.status === 'active');

      if (hasActiveShop) {
        await this.$router.push({
          name: 'UserShops',
        });

        Toast.info('You already have an active shop');
      }
    } catch (e) {
    //
    }

  },
  methods: {
    clearFileSelect(type)  {

      if(type === 'logo') {
        this.shopCreateForm.logo = null;
        this.logoPreview = null;
        return;
      }

      if(type === 'banner') {
        this.shopCreateForm.banner = null;
        this.bannerPreview = null;
        return;
      }
    },
    handleLogoSelect({ blob, preview }){

      if(!preview) {
        this.shopCreateForm.logo = null;
        this.logoPreview = null;
        return;
      }

      this.shopCreateForm.logo = blob;
      this.logoPreview = preview;
    },
    handleBannerSelect({blob, preview}){
      if(!preview) {
        this.shopCreateForm.banner = null;
        this.bannerPreview = null;
        return;
      }

      this.shopCreateForm.banner = blob;
      this.bannerPreview = preview;
    },
    handleLogoInput(file) {

      if (!file) {
        this.shopCreateForm.logo = null;
        return;
      }

      this.shopCreateForm.logo = file;
      this.logoPreview = URL.createObjectURL(file);
      URL.revokeObjectURL(file); // free memory
    },
    handleBannerInput(file) {

      if (!file) {
        this.shopCreateForm.banner = null;
        return;
      }

      this.shopCreateForm.banner = file;
      this.bannerPreview = URL.createObjectURL(file);
      URL.revokeObjectURL(file); // free memory
    },
    async handleShopCreateRequest() {
      let { name, slug, about, cell, email, address, logo, banner } = this.shopCreateForm;
      let data = new FormData();

      data.append('name', name);
      data.append('slug', slug);
      data.append('about', about);
      data.append('cell', cell);
      data.append('email', email);
      data.append('address', address);
      data.append('logo', logo);
      data.append('banner', banner);

      let socialData = this.shopCreateForm.social_media;

      for (let key in socialData) {
        data.append(`social_media[${key}]`, socialData[key]);
      }
      this.formSubmitting = true;
      try {

        let response = await shopCreateRequest(data);

        Toast.success(response.data.message || 'Your Request is accepted, Please wait for the confirmation');

        this.formSubmitting = false;
        await this.$router.push({ name: routeMap.user.shops.name });

      } catch (e) {

        this.errors = e.errors;
        this.formSubmitting = false;

        Toast.error(e.message || 'There is an error, Please try later');
      }
    }
  }
};
</script>

<style scoped lang="scss">
.shop-request-form-header {
  background: #202e82;
  .v-card__title {
    color: #ffffff;
  }
}
</style>
